<template>
  <div class="m-page">
    <img src="https://img.miaocang.cc/pcImg/cloud/2023-introduction.jpg" alt="" />
  </div>
</template>

<script>
export default {
  name: 'CloudExhibitionIntroduction2023',

created() {
  document.title = '展会介绍'
}
}
</script>

<style lang="less" scoped>
.m-page {
  min-height: 100vh;
  max-width: 840px;
  text-align: center;
  background-color: #000;
  img {
    width: 100vw;
    min-width: 320px;
    max-width: 840px;
  }
}
</style>